import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MainLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Our products`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The below listed veneers are available for delivery in 0.6 mm, 1 mm e 2 mm thickness:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Italian Walnut`}</li>
          <li parentName="ul">{`Cherry`}</li>
          <li parentName="ul">{`Hard maple`}</li>
          <li parentName="ul">{`Red maple`}</li>
          <li parentName="ul">{`Chestnut`}</li>
          <li parentName="ul">{`Oak`}</li>
          <li parentName="ul">{`European White Ash Tree`}</li>
          <li parentName="ul">{`Cedar`}</li>
          <li parentName="ul">{`Acacia`}</li>
          <li parentName="ul">{`Briar Root`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Wood veneers from other essences and in different thickness are available upon request. `}<a parentName="p" {...{
            "href": "/en/contacts"
          }}>{`Contact us`}</a>{` for information on our available stock or for a customised product.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      